<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">保护区划界线描述与管理规定</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :model="formdata.saveJson"
                :rules="rules"
                :inline="true"
                class="form_row"
                size="small"
                label-width="200px"
            >
                <!-- 保护区划界线描述与管理规定 -->
                <div
                    v-for="(item, index) in formdata.saveJson.hcqycqlist"
                    :key="'hcqycq' + index"
                    class="content_box"
                >
                    <!-- 操作按钮 -->
                    <div class="top_content">
                        <span class="menu_title">缓冲区与遗产区界线</span>
                        <!-- 添加删除按钮 -->
                        <div v-if="isEdit" class="button_list">
                            <sn-button
                                :snButton="
                                    index === 0 ? addButton : deleteButton
                                "
                                @handleChange="changeHcqdata(index)"
                            ></sn-button>
                        </div>
                    </div>
                    <!-- 缓冲区 -->
                    <div>
                        <el-form-item
                            label="缓冲区名称"
                            :prop="'hcqycqlist.' + index + '.hcq.hcqmc'"
                            :rules="[
                                {
                                    required: true,
                                    message: '请输入缓冲区名称',
                                    trigger: 'blur',
                                },
                                { validator: checkHcqmc, trigger: 'blur' },
                            ]"
                        >
                            <el-input
                                v-model="item.hcq.hcqmc"
                                :maxlength="50"
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="缓冲区面积（公顷）"
                            :prop="'hcqycqlist.' + index + '.hcq.hcqmj'"
                            :rules="{
                                required: true,
                                message: '请输入缓冲区面积（公顷）',
                                trigger: 'blur',
                            }"
                        >
                            <el-input
                                v-model="item.hcq.hcqmj"
                                :maxlength="50"
                                :disabled="!isEdit"
                                @input="
                                    item.hcq.hcqmj = (item.hcq.hcqmj.match(
                                        /\d+(\.\d{0,})?/
                                    ) || [''])[0]
                                "
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="缓冲区界线"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item.hcq.hcqjx"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="1500"
                                show-word-limit
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="缓冲区管理规定"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item.hcq.hcqglgd"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="1500"
                                show-word-limit
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <!-- 遗产区 -->
                    <div
                        v-for="(item1, index1) in item.ycqlist"
                        :key="index1"
                        class="ycq_content"
                    >
                        <el-form-item
                            label="遗产区名称"
                            :prop="
                                'hcqycqlist.' +
                                index +
                                '.ycqlist.' +
                                index1 +
                                '.ycqmc'
                            "
                            :rules="[
                                {
                                    required: true,
                                    message: '请输入遗产区名称',
                                    trigger: 'blur',
                                },
                                ,
                                { validator: checkYcqmc, trigger: 'blur' },
                            ]"
                        >
                            <el-input
                                v-model="item1.ycqmc"
                                :maxlength="50"
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="遗产区面积（公顷）"
                            :prop="
                                'hcqycqlist.' +
                                index +
                                '.ycqlist.' +
                                index1 +
                                '.ycqmj'
                            "
                            :rules="{
                                required: true,
                                message: '请输入遗产区面积（公顷）',
                                trigger: 'blur',
                            }"
                        >
                            <el-input
                                v-model="item1.ycqmj"
                                :maxlength="50"
                                :disabled="!isEdit"
                                @input="
                                    item1.ycqmj = (item1.ycqmj.match(
                                        /\d+(\.\d{0,})?/
                                    ) || [''])[0]
                                "
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="遗产区界线"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item1.ycqjx"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="1500"
                                show-word-limit
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="遗产区管理规定"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item1.ycqglgd"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="1500"
                                show-word-limit
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                        <div v-if="isEdit" class="ycq_Button">
                            <i
                                :class="
                                    index1 === 0
                                        ? 'el-icon-circle-plus'
                                        : 'el-icon-remove'
                                "
                                @click="changeYCQList(index, index1)"
                            ></i>
                        </div>
                    </div>
                </div>
                <!-- 建设控制地带与保护范围界线 -->
                <div
                    v-for="(item, index) in formdata.saveJson.jkddlist"
                    :key="'jkddbhfw' + index"
                    class="content_box"
                >
                    <div class="top_content">
                        <span class="menu_title"
                            >建设控制地带与保护范围界线</span
                        >
                        <div v-if="isEdit" class="button_list">
                            <sn-button
                                :snButton="
                                    index === 0 ? addButton : deleteButton
                                "
                                @handleChange="changeJkdddata(index)"
                            ></sn-button>
                        </div>
                    </div>
                    <div>
                        <el-form-item
                            label="是否与缓冲区一致"
                            :prop="'jkddlist.' + index + '.jkdd.sfyhcqyz'"
                        >
                            <el-radio-group
                                v-model="item.jkdd.sfyhcqyz"
                                :disabled="!isEdit"
                                @change="
                                    (val) => {
                                        changeSameHcq(val, index);
                                    }
                                "
                            >
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                            v-if="item.jkdd.sfyhcqyz === '1'"
                            label="对应缓冲区名称"
                            :prop="'jkddlist.' + index + '.jkdd.dyhcqmc'"
                            :rules="{
                                required: true,
                                message: '选择对应缓冲区名称',
                                trigger: 'blur',
                            }"
                        >
                            <el-select
                                v-model="item.jkdd.dyhcqmc"
                                filterable
                                clearable
                                placeholder="请选择对应缓冲区名称"
                                :disabled="!isEdit"
                                @change="
                                    (val) => {
                                        chooseHcqmc(val, index);
                                    }
                                "
                                @visible-change="
                                    (val) => {
                                        visibleChange(val, 'changeHcqmc');
                                    }
                                "
                            >
                                <el-option
                                    v-for="(ele, index) in hcqList"
                                    :key="index"
                                    :label="ele.name"
                                    :value="ele.name"
                                    :disabled="!isEdit"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            v-if="item.jkdd.sfyhcqyz === '0'"
                            label="国保单位"
                            :prop="'jkddlist.' + index + '.jkdd.dyhcqmc'"
                        >
                            <el-select
                                v-model="item.jkdd.gbdwid"
                                filterable
                                clearable
                                placeholder="请选择对应国保单位"
                                :disabled="!isEdit"
                            >
                                <el-option
                                    v-for="(ele, index) in gbdwList"
                                    :key="index"
                                    :label="ele.DWMC"
                                    :value="ele.ID"
                                    :disabled="!isEdit"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="建设控制地带名称"
                            :prop="'jkddlist.' + index + '.jkdd.jskzddmc'"
                            :rules="{
                                required: true,
                                message: '请输入建设控制地带名称',
                                trigger: 'blur',
                            }"
                        >
                            <el-input
                                v-model="item.jkdd.jskzddmc"
                                :maxlength="50"
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="建设控制地带面积（公顷）"
                            :prop="'jkddlist.' + index + '.jkdd.jskzddmj'"
                            :rules="{
                                required: true,
                                message: '请输入建设控制地带面积（公顷）',
                                trigger: 'blur',
                            }"
                        >
                            <el-input
                                v-model="item.jkdd.jskzddmj"
                                :maxlength="50"
                                :disabled="!isEdit || item.jkdd.sfyhcqyz == '1'"
                                @input="
                                    item.jkdd.jskzddmj =
                                        (item.jkdd.jskzddmj.match(
                                            /\d+(\.\d{0,})?/
                                        ) || [''])[0]
                                "
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="建设控制地带界线"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item.jkdd.jskzddjx"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="1500"
                                show-word-limit
                                :disabled="!isEdit || item.jkdd.sfyhcqyz == '1'"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="建设控制地带管理规定"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item.jkdd.jskzddglgd"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="1500"
                                show-word-limit
                                :disabled="!isEdit || item.jkdd.sfyhcqyz == '1'"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div
                        v-for="(item1, index1) in item.bhfwlist"
                        :key="index1"
                        class="ycq_content"
                    >
                        <el-form-item
                            label="是否与遗产区一致"
                            :prop="
                                'jkddlist.' +
                                index +
                                '.bhfwlist.' +
                                index1 +
                                '.sfyycqyz'
                            "
                        >
                            <el-radio-group
                                v-model="item1.sfyycqyz"
                                :disabled="!isEdit"
                                @change="
                                    (val) => {
                                        changeSameYcq(val, index, index1);
                                    }
                                "
                            >
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="item1.sfyycqyz === '0'">
                            <!-- 站位 -->
                        </el-form-item>
                        <el-form-item
                            v-if="item1.sfyycqyz === '1'"
                            label="对应遗产区名称"
                            :prop="
                                'jkddlist.' +
                                index +
                                '.bhfwlist.' +
                                index1 +
                                '.dyycqmc'
                            "
                            :rules="{
                                required: true,
                                message: '选择对对应遗产区名称',
                                trigger: 'blur',
                            }"
                        >
                            <el-select
                                v-model="item1.dyycqmc"
                                filterable
                                clearable
                                placeholder="请选择对应遗产区名称"
                                :disabled="!isEdit"
                                @change="
                                    (val) => {
                                        chooseYcqmc(val, index, index1);
                                    }
                                "
                                @visible-change="
                                    (val) => {
                                        visibleChange(val, 'changeYcqmc');
                                    }
                                "
                            >
                                <el-option
                                    v-for="(ele, index) in ycqList"
                                    :key="index"
                                    :label="ele.name"
                                    :value="ele.name"
                                    :disabled="!isEdit"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="保护范围名称"
                            :prop="
                                'jkddlist.' +
                                index +
                                '.bhfwlist.' +
                                index1 +
                                '.bhfwmc'
                            "
                            :rules="{
                                required: true,
                                message: '请输入保护范围名称',
                                trigger: 'blur',
                            }"
                        >
                            <el-input
                                v-model="item1.bhfwmc"
                                :maxlength="50"
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="保护范围面积（公顷）"
                            :prop="
                                'jkddlist.' +
                                index +
                                '.bhfwlist.' +
                                index1 +
                                '.bhfwmj'
                            "
                            :rules="{
                                required: true,
                                message: '请输入保护范围面积（公顷）',
                                trigger: 'blur',
                            }"
                        >
                            <el-input
                                v-model="item1.bhfwmj"
                                :maxlength="50"
                                :disabled="!isEdit || item1.sfyycqyz === '1'"
                                @input="
                                    item1.bhfwmj = (item1.bhfwmj.match(
                                        /\d+(\.\d{0,})?/
                                    ) || [''])[0]
                                "
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="保护范围界线"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item1.bhfwjx"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="1500"
                                show-word-limit
                                :disabled="!isEdit || item1.sfyycqyz === '1'"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="保护范围管理规定"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="item1.bhfwglgd"
                                type="textarea"
                                :autosize="{ minRows: 6 }"
                                maxlength="1500"
                                show-word-limit
                                :disabled="!isEdit || item1.sfyycqyz === '1'"
                            ></el-input>
                        </el-form-item>
                        <div v-if="isEdit" class="ycq_Button">
                            <i
                                :class="
                                    index1 === 0
                                        ? 'el-icon-circle-plus'
                                        : 'el-icon-remove'
                                "
                                @click="changeBhfwList(index, index1)"
                            ></i>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import alertInfo from "@comp/alert_info";
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "protection_zone_description",
    components: {
        alertInfo,
    },
    mixins: [infoMixin],
    data() {
        return {
            rules: {},
            checkHcqmc: (rule, value, callback) => {
                let res = this.hcqList.filter((item) => {
                    return item.name === String(value);
                });
                if (res.length > 1) {
                    callback(new Error("缓冲区名称不可重复"));
                } else {
                    this.changeHcqmc();
                    callback();
                }
            },
            checkYcqmc: (rule, value, callback) => {
                let res = this.ycqList.filter((item) => {
                    return item.name === value;
                });
                if (res.length > 1) {
                    callback(new Error("遗产区名称不可重复"));
                } else {
                    this.changeYcqmc();
                    callback();
                }
            },
            snButton: snButton,
            //缓冲区与遗产区
            hcqycqData: {
                hcq: {
                    id: "",
                    hcqmc: "", //缓冲区名称
                    hcqmj: "", //缓冲区面积
                    hcqjx: "", //缓冲区界线
                    hcqglgd: "", //缓冲区管理规定
                    sort: null,
                },
                ycqlist: [
                    {
                        id: "",
                        ycqmc: "", //遗产区名称
                        ycqmj: "", //遗产区面积
                        ycqjx: "", //遗产区界线
                        ycqglgd: "", //遗产区管理规定
                        sort: null,
                    },
                ],
            },
            // 建设控制地带与保护范围
            jkddData: {
                //监控地带
                jkdd: {
                    id: "",
                    jskzddmc: "", //建设控制地带名称
                    sfyhcqyz: "0", //是否与缓冲区一致
                    dyhcqid: "", //对应缓冲区id 是
                    dyhcqmc: "", //对应缓冲区名称 是
                    gbdwmc: "", //国保单位名称 否
                    gbdwid: "", //国保单位id 否
                    jskzddmj: "", //建设控制地带面积
                    jskzddjx: "", //建设控制地带界线
                    jskzddglgd: "", //建设控制地带管理规定
                    sort: null,
                },
                //保护范围
                bhfwlist: [
                    {
                        id: "",
                        bhfwmc: "", //保护范围名称
                        sfyycqyz: "0", //是否与遗产区一致
                        dyycqid: "", //对应遗产区id
                        dyycqmc: "", //对应遗产区名称 是
                        bhfwmj: "", //保护范围名称
                        bhfwjx: "", //保护范围界线
                        bhfwglgd: "", //保护范围管理规定
                        sort: null,
                    },
                ],
            },
            hcqycqlist: [], //遗产区缓冲区 列表
            jkddlist: [], //建设控制地带与保护范围界线 列表
            // 参数
            formdata: {
                saveJson: {
                    hcqycqlist: [], //缓冲区与遗产区
                    jkddlist: [], //建设控制地带与保护范围界线
                    mainlist: [
                        {
                            id: "",
                            GLYCBTID: "",
                            BBSX: "",
                            shzt: "",
                            CJRID: null,
                            RWID: null,
                        },
                    ],
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "21",
                userName: "",
                userId: "",
            },
            addButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "changeHcqdata",
                        name: "添加",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                ],
            },
            deleteButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "changeHcqdata",
                        name: "删除",
                        round: true,
                        backColor: "#ff9066",
                        color: "#fff",
                    },
                ],
            },
            ycqList: [], //遗产区列表
            hcqList: [], //缓冲区区列表
            // 国宝列表
            gbdwList: [
                {
                    name: "国保1",
                    id: "1",
                },
                {
                    name: "国保2",
                    id: "2",
                },
            ],
            hcqdelData: [], //缓冲区 删除
            ycqdelData: [], //遗产区区 删除
            jkdddelData: [], //建控地带 删除
            bhfwdelData: [], //保护范围 删除
            hcqYcqListNewNum: 0,
        };
    },
    computed: {
        hcqYcqListNew() {
            return JSON.parse(
                JSON.stringify(this.formdata.saveJson.hcqycqlist)
            );
        },
        ...mapGetters(["heritageId"]),
    },
    created() {},
    watch: {
        hcqYcqListNew: {
            immediate: false,
            deep: true,
            handler: function (newList, oldList) {
                // 初始状态 不进行比较
                if (this.hcqYcqListNewNum < 2) {
                    this.hcqYcqListNewNum++;
                    return;
                }
                newList.map((item, index) => {
                    let oldItemHcq = oldList[index].hcq;
                    // 缓冲区名称 改变
                    if (item.hcq.hcqmc != oldItemHcq.hcqmc) {
                        this.changeDemoHcqName(item.hcq, oldItemHcq);
                    }

                    // 缓冲区面积 改变
                    if (item.hcq.hcqmj != oldItemHcq.hcqmj) {
                        this.changeDemoHcqMj(item.hcq, oldItemHcq);
                    }
                    // 缓冲区界限 改变
                    if (item.hcq.hcqjx != oldItemHcq.hcqjx) {
                        this.changeDemoHcqJx(item.hcq, oldItemHcq);
                    }
                    // 缓冲区管理规定 改变
                    if (item.hcq.hcqglgd != oldItemHcq.hcqglgd) {
                        this.changeDemoHcqGd(item.hcq, oldItemHcq);
                    }
                    item.ycqlist.map((itemInner, indexInner) => {
                        // 遗产区名称 改变
                        let oldItem = oldList[index].ycqlist[indexInner];
                        if (itemInner.ycqmc != oldItem.ycqmc) {
                            this.changeDemoYcqName(itemInner, oldItem);
                        }
                        // 遗产区面积 改变
                        if (itemInner.ycqmj != oldItem.ycqmj) {
                            this.changeDemoYcqMj(itemInner, oldItem);
                        }

                        // 遗产区界线 改变
                        if (itemInner.ycqjx != oldItem.ycqjx) {
                            this.changeDemoYcqJx(itemInner, oldItem);
                        }

                        // 遗产区管理规定 改变
                        if (itemInner.ycqglgd != oldItem.ycqglgd) {
                            this.changeDemoYcqGd(itemInner, oldItem);
                        }
                    });
                });
            },
        },
    },
    mounted() {
        this.getGbdwList();
        this.hcqycqlist.push(JSON.parse(JSON.stringify(this.hcqycqData)));
        this.jkddlist.push(JSON.parse(JSON.stringify(this.jkddData)));
        this.formdata.saveJson.hcqycqlist = this.hcqycqlist;
        this.formdata.saveJson.jkddlist = this.jkddlist;
        this.formdata.saveJson.mainlist[0].GLYCBTID = this.heritageId;
        this.formdata.saveJson.mainlist[0].CJRID =
            localStorage.getItem("userId");
        this.formdata.saveJson.mainlist[0].RWID = this.$route.query.RWID;
        this.formdata.heritageId = this.heritageId;

        console.log(
            "%c 🥑 this.formdata.saveJson: ",
            "font-size:20px;background-color: #33A5FF;color:#fff;",
            this.formdata
        );
    },
    methods: {
        ...mapActions(["getBasicList"]),
        // 改变 缓冲区
        changeHcqdata(index) {
            if (this.isEdit) {
                if (index === 0) {
                    // 增加
                    let obj = JSON.parse(JSON.stringify(this.hcqycqData));
                    this.formdata.saveJson.hcqycqlist.push(obj);
                } else {
                    // 删除
                    let data = this.formdata.saveJson.hcqycqlist[index];
                    this.clearHcqYcqDemo(data); //清空 利用 缓冲区,遗产区  模板产生的数据
                    if (data.hcq.id) {
                        this.hcqdelData.push(data.hcq.id);
                    }
                    data.ycqlist.forEach((item) => {
                        if (item.id) {
                            this.ycqdelData.push(item.id);
                        }
                    });
                    this.formdata.saveJson.hcqycqlist.splice(index, 1);
                    this.changeHcqmc(); //初始化 缓冲区列表
                    this.changeYcqmc(); //初始化 缓冲区列表
                }
            }
        },
        // 改变 遗产区
        changeYCQList(index, index1) {
            if (this.isEdit) {
                if (index1 === 0) {
                    // 增加
                    let obj = JSON.parse(JSON.stringify(this.hcqycqData))
                        .ycqlist[0];
                    this.formdata.saveJson.hcqycqlist[index].ycqlist.push(obj);
                } else {
                    let data =
                        this.formdata.saveJson.hcqycqlist[index].ycqlist[
                            index1
                        ];
                    if (data.id) {
                        this.ycqdelData.push(data.id);
                    }
                    this.formdata.saveJson.hcqycqlist[index].ycqlist.splice(
                        index1,
                        1
                    );
                    this.changeYcqmc();
                    this.clearYcqDemo(data);
                }
            }
        },
        // 改变 建控地带
        changeJkdddata(index) {
            if (this.isEdit) {
                if (index === 0) {
                    // 增加
                    let obj = JSON.parse(JSON.stringify(this.jkddData));
                    this.formdata.saveJson.jkddlist.push(obj);
                } else {
                    let data = this.formdata.saveJson.jkddlist[index];
                    if (data.jkdd.id) {
                        this.jkdddelData.push(data.jkdd.id);
                    }
                    data.bhfwlist.forEach((item) => {
                        if (item.id) {
                            this.bhfwdelData.push(item.id);
                        }
                    });
                    this.formdata.saveJson.jkddlist.splice(index, 1);
                }
            }
        },
        // 改变 保护范围
        changeBhfwList(index, index1) {
            if (this.isEdit) {
                if (index1 === 0) {
                    // 增加
                    let obj = JSON.parse(JSON.stringify(this.jkddData))
                        .bhfwlist[0];
                    this.formdata.saveJson.jkddlist[index].bhfwlist.push(obj);
                } else {
                    let data =
                        this.formdata.saveJson.jkddlist[index].bhfwlist[index1];
                    if (data.id) {
                        this.jkdddelData.push(data.id);
                    }
                    this.formdata.saveJson.jkddlist[index].bhfwlist.splice(
                        index1,
                        1
                    );
                }
            }
        },
        // select 出现 获取 缓冲区 遗产区 列表
        visibleChange(value, method) {
            if (value) {
                this[method]();
            }
        },
        // 获取当前页面的缓冲区列表
        changeHcqmc() {
            let hcqList = [];
            this.formdata.saveJson.hcqycqlist.forEach((item, index) => {
                hcqList.push({
                    name: item.hcq.hcqmc,
                    key: index,
                    mj: item.hcq.hcqmj,
                    jx: item.hcq.hcqjx,
                    glgd: item.hcq.hcqglgd,
                });
            });
            this.hcqList = hcqList;
        },
        // 获取当前页面的遗产区列表
        changeYcqmc() {
            let ycqList = [];
            this.formdata.saveJson.hcqycqlist.forEach((item, index) => {
                item.ycqlist.forEach((item1, index1) => {
                    ycqList.push({
                        name: item1.ycqmc,
                        key: index + "," + index1,
                        mj: item1.ycqmj,
                        jx: item1.ycqjx,
                        glgd: item1.ycqglgd,
                    });
                });
            });
            this.ycqList = ycqList;
        },
        // 建设控制地带  选择对应缓冲区名称,默认赋值
        chooseHcqmc(name, index) {
            let res = this.hcqList.filter((item) => {
                return item.name === name;
            });
            if (res.length > 0) {
                let jkdd = this.formdata.saveJson.jkddlist[index].jkdd;
                jkdd.jskzddmj = res[0].mj;
                jkdd.jskzddjx = res[0].jx;
                jkdd.jskzddglgd = res[0].glgd;
            }
        },
        // 保护范围界线 选择对应遗产区名称,默认赋值
        chooseYcqmc(name, index, index1) {
            let res = this.ycqList.filter((item) => {
                return item.name === name;
            });
            if (res.length > 0) {
                let bhfwlist =
                    this.formdata.saveJson.jkddlist[index].bhfwlist[index1];
                bhfwlist.bhfwmj = res[0].mj;
                bhfwlist.bhfwjx = res[0].jx;
                bhfwlist.bhfwglgd = res[0].glgd;
            }
        },
        // 保存时的数据处理
        operateData() {
            // 建设控制地带与保护范围界线
            this.formdata.saveJson.jkddlist.map((item, index) => {
                // 是否与缓冲区一致 是
                if (item.jkdd.sfyhcqyz == "1") {
                    // id赋值 修改的时候  id 暂时不传给后台
                    // if (this.id) {
                    //     this.formdata.saveJson.hcqycqlist.map((itemInner) => {
                    //         if (itemInner.hcq.hcqmc == item.jkdd.dyhcqmc) {
                    //             item.jkdd.dyhcqid = itemInner.hcq.id;
                    //         }
                    //     });
                    // }

                    // 清空 国保单位
                    item.jkdd.gbdwid = "";
                    item.jkdd.gbdwmc = "";
                } else {
                    item.jkdd.dyhcqid = "";
                    item.jkdd.dyhcqmc = "";
                    item.jkdd.gbdwmc = this.common.aryListFindOne(
                        this.gbdwList,
                        "ID",
                        item.jkdd.gbdwid
                    ).name;
                }
                // 是否与遗产区一致
                item.bhfwlist.map((itemInner) => {
                    // 对应遗产区名称 传"""  否
                    if (itemInner.sfyycqyz == "0") {
                        itemInner.dyycqmc = "";
                        itemInner.dyycqid = "";
                    } else {
                        // id 暂时不传给后台
                        // this.formdata.saveJson.hcqycqlist.map((itemInneR2) => {
                        //     itemInneR2.ycqlist.map((itemInneR3) => {
                        //         if (itemInner.dyycqmc == itemInneR3.ycqmc) {
                        //             itemInner.dyycqid = itemInneR3.id;
                        //         }
                        //     });
                        // });
                    }
                });
            });

            // 遗产区和缓冲区 sort 赋值
            if (this.formdata.saveJson.hcqycqlist) {
                this.formdata.saveJson.hcqycqlist.forEach((item, index) => {
                    item.hcq.sort = index + 1;
                    item.ycqlist.forEach((item1, index1) => {
                        item1.sort = index1 + 1;
                    });
                });
            }
            // 删除 缓冲区数据
            if (this.hcqdelData.length) {
                this.DeleteBhqhYcqOrHcq({
                    ids: this.hcqdelData.join(),
                    qylx: "1",
                });
            }
            // 删除 遗产区数据
            if (this.ycqdelData.length) {
                this.DeleteBhqhYcqOrHcq({
                    ids: this.ycqdelData.join(),
                    qylx: "2",
                });
            }
            // 建控地带和保护范围
            if (this.formdata.saveJson.jkddlist) {
                this.formdata.saveJson.jkddlist.forEach((item, index) => {
                    item.jkdd.sort = index + 1;
                    item.bhfwlist.forEach((item1, index1) => {
                        item1.sort = index1 + 1;
                    });
                });
            }
            // 删除 监控地带数据
            if (this.jkdddelData.length) {
                this.DeleteBhqhYcqOrHcq({
                    ids: this.jkdddelData.join(),
                    qylx: "3",
                });
            }
            // 删除 保护范围数据
            if (this.bhfwdelData.length) {
                this.DeleteBhqhYcqOrHcq({
                    ids: this.bhfwdelData.join(),
                    qylx: "4",
                });
            }
            // 条件验证失败
        },
        //清空  缓冲区 模板
        clearHcqYcqDemo(obj) {
            // 建设控制地带
            this.formdata.saveJson.jkddlist.map((item, index) => {
                // 是否与缓冲区一致 是
                if (
                    item.jkdd.sfyhcqyz == "1" &&
                    obj.hcq.hcqmc == item.jkdd.dyhcqmc
                ) {
                    item.jkdd.dyhcqmc = "";
                    item.jkdd.jskzddmj = "";
                    item.jkdd.jskzddjx = "";
                    item.jkdd.jskzddglgd = "";
                }
                // 是否与遗产区一致 是
                item.bhfwlist.map((itemInner) => {
                    if (itemInner.sfyycqyz == "1") {
                        obj.ycqlist.map((itemYcq) => {
                            if (obj.hcq.hcqmc == itemYcq.dyycqmc) {
                                itemInner.dyycqmc = "";
                                itemInner.bhfwmj = "";
                                itemInner.bhfwjx = "";
                                itemInner.bhfwglgd = "";
                            }
                        });
                    }
                });
            });
            // 保护范围界线
            let nameList = [];
            obj.ycqlist.map((item) => {
                nameList.push(item.ycqmc);
            });
            this.formdata.saveJson.jkddlist.map((item, index) => {
                item.bhfwlist.map((itemInner) => {
                    if (
                        nameList.includes(itemInner.dyycqmc) &&
                        itemInner.sfyycqyz == "1"
                    ) {
                        itemInner.dyycqmc = "";
                        itemInner.bhfwglgd = "";
                        itemInner.bhfwjx = "";
                        itemInner.bhfwmj = "";
                    }
                });
            });
        },
        //清空  遗产区 模板
        clearYcqDemo(obj) {
            // 保护范围界线
            this.formdata.saveJson.jkddlist.map((item, index) => {
                item.bhfwlist.map((itemInner) => {
                    if (
                        obj.ycqmc == itemInner.dyycqmc &&
                        itemInner.sfyycqyz == "1"
                    ) {
                        itemInner.dyycqmc = "";
                        itemInner.bhfwglgd = "";
                        itemInner.bhfwjx = "";
                        itemInner.bhfwmj = "";
                    }
                });
            });
        },

        // 模型改变------------
        // 模型 缓冲区名称
        changeDemoHcqName(newObJ, oldObj) {
            // 建设控制地带
            this.formdata.saveJson.jkddlist.map((item, index) => {
                // 是否与缓冲区一致 是
                if (
                    item.jkdd.sfyhcqyz == "1" &&
                    item.jkdd.dyhcqmc == oldObj.hcqmc
                ) {
                    // item.jkdd.dyhcqmc = newObJ.hcqmc;
                    item.jkdd.dyhcqmc = "";
                    item.jkdd.jskzddmj = "";
                    item.jkdd.jskzddjx = "";
                    item.jkdd.jskzddglgd = "";
                }
            });
        },
        // 模型 缓冲区面积
        changeDemoHcqMj(newObJ, oldObj) {
            // 建设控制地带
            this.formdata.saveJson.jkddlist.map((item, index) => {
                // 是否与缓冲区一致 是
                if (
                    item.jkdd.sfyhcqyz == "1" &&
                    item.jkdd.jskzddmj == oldObj.hcqmj
                ) {
                    item.jkdd.jskzddmj = newObJ.hcqmj;
                }
            });
        },
        // 模型 缓冲区界线
        changeDemoHcqJx(newObJ, oldObj) {
            // 建设控制地带
            this.formdata.saveJson.jkddlist.map((item, index) => {
                // 是否与缓冲区一致 是
                if (
                    item.jkdd.sfyhcqyz == "1" &&
                    item.jkdd.jskzddjx == oldObj.hcqjx
                ) {
                    item.jkdd.jskzddjx = newObJ.hcqjx;
                }
            });
        },
        // 模型 缓冲区管理规定
        changeDemoHcqGd(newObJ, oldObj) {
            // 建设控制地带
            this.formdata.saveJson.jkddlist.map((item, index) => {
                // 是否与缓冲区一致 是
                if (
                    item.jkdd.sfyhcqyz == "1" &&
                    item.jkdd.jskzddglgd == oldObj.hcqglgd
                ) {
                    item.jkdd.jskzddglgd = newObJ.hcqglgd;
                }
            });
        },
        // 模型 缓冲区名称
        changeDemoYcqName(newObJ, oldObj) {
            // 保护范围
            this.formdata.saveJson.jkddlist.map((item) => {
                item.bhfwlist.map((itemInner) => {
                    // 是否与缓冲区一致 是
                    if (
                        itemInner.sfyycqyz == "1" &&
                        itemInner.dyycqmc == oldObj.ycqmc
                    ) {
                        // itemInner.dyycqmc = newObJ.ycqmc;
                        itemInner.dyycqmc = "";
                        itemInner.bhfwmj = "";
                        itemInner.bhfwjx = "";
                        itemInner.bhfwglgd = "";
                    }
                });
            });
        },
        // 模型 缓冲区面积
        changeDemoYcqMj(newObJ, oldObj) {
            // 保护范围
            this.formdata.saveJson.jkddlist.map((item) => {
                item.bhfwlist.map((itemInner) => {
                    // 是否与缓冲区一致 是
                    if (
                        itemInner.sfyycqyz == "1" &&
                        itemInner.bhfwmj == oldObj.ycqmj
                    ) {
                        itemInner.bhfwmj = newObJ.ycqmj;
                    }
                });
            });
        },
        // 模型 缓冲区界线
        changeDemoYcqJx(newObJ, oldObj) {
            // 保护范围
            this.formdata.saveJson.jkddlist.map((item) => {
                item.bhfwlist.map((itemInner) => {
                    // 是否与缓冲区一致 是
                    if (
                        itemInner.sfyycqyz == "1" &&
                        itemInner.bhfwjx == oldObj.ycqjx
                    ) {
                        itemInner.bhfwjx = newObJ.ycqjx;
                    }
                });
            });
        },
        // 模型 缓冲区管理规定
        changeDemoYcqGd(newObJ, oldObj) {
            // 保护范围
            this.formdata.saveJson.jkddlist.map((item) => {
                item.bhfwlist.map((itemInner) => {
                    // 是否与缓冲区一致 是
                    if (
                        itemInner.sfyycqyz == "1" &&
                        itemInner.bhfwglgd == oldObj.ycqglgd
                    ) {
                        itemInner.bhfwglgd = newObJ.ycqglgd;
                    }
                });
            });
        },
        // 是否与缓冲区一致 当选择否的时候 清空该项的 对应缓冲区名称
        changeSameHcq(val, index) {
            if (val == 0) {
                this.formdata.saveJson.jkddlist[index].jkdd.dyhcqmc = "";
                this.formdata.saveJson.jkddlist[index].jkdd.jskzddmj = "";
                this.formdata.saveJson.jkddlist[index].jkdd.jskzddjx = "";
                this.formdata.saveJson.jkddlist[index].jkdd.jskzddglgd = "";
            }
        },
        // 是否与遗产区一致 当选择否的时候 清空该项的 对应遗产区名称
        changeSameYcq(val, index, index1) {
            if (val == 0) {
                this.formdata.saveJson.jkddlist[index].bhfwlist[
                    index1
                ].dyycqmc = "";
                this.formdata.saveJson.jkddlist[index].bhfwlist[index1].bhfwmj =
                    "";
                this.formdata.saveJson.jkddlist[index].bhfwlist[index1].bhfwjx =
                    "";
                this.formdata.saveJson.jkddlist[index].bhfwlist[
                    index1
                ].bhfwglgd = "";
            }
        },
        // 获取国宝单位
        async getGbdwList() {
            let result = await this.getBasicList({
                userid: localStorage.userId,
                ycdid: this.heritageId,
                itemId: 8,
                authority: false,
                pageIndex: 1,
                pageSize: 99999,
                keyWord: "",
                strwhere: "",
                sort: "",
                order: "",
            });
            this.gbdwList = result.ResultValue.gridData;
        },
    },
};
</script>
<style lang="scss" scoped>
.content_box {
    border: 1px solid#C0C4CC;
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
}
.content_box > div:not(.top_content) {
    background-color: white;
    margin-top: 10px;
    padding-right: 100px;
}
.top_content {
    height: 40px;
    .menu_title {
        line-height: 40px;
    }
}
.ycq_content {
    position: relative;
}
.ycq_Button {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translate(50%, -50%);
    cursor: pointer;
    i {
        font-size: 25px;
    }
    .el-icon-circle-plus {
        color: #28cdd9;
    }
    .el-icon-remove {
        color: #ff9066;
    }
}
</style>
